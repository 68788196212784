import axios from "axios";

class AttachmentManager {
    constructor(selector) {
        this.container = selector;
        this.entityClassName = this.container.dataset.attachments;
        if (!this.entityClassName) {
            console.log(`Could not found entity class name in ${this.container}`);
            return;
        }
        this.imageItems = Array.from(this.container.querySelectorAll('[data-attachment]'));

        this.bind();
    }

    bind() {
        this.imageItems.forEach((item) => {
            this.bindDraggable(item);

            const removeButton = item.querySelector('[data-attachment-remove]');
            removeButton.addEventListener('click', (e) => {
                e.stopPropagation();
                this.removeItem(item);
            });
        });
    }

    bindDraggable(item) {
        item.draggable = true;
        item.ondragstart = (e) => {
            e.dataTransfer.setData("id", e.target.id);
            e.target.classList.add('_dragging');
        };
        item.ondragover = (e) => {
            let old = document.querySelector('._over');
            old && old.classList.remove('_over');
            e.target.classList.add('_over');
            e.preventDefault();
        };
        item.ondrop = (e) => {
            let old = document.querySelector('._dragging');
            old && old.classList.remove('_dragging');
            old = document.querySelector('._over');
            old && old.classList.remove('_over');
            let newContent = e.target.innerHTML;
            let newContentId = e.target.dataset.attachment;
            let fromEl = document.getElementById(e.dataTransfer.getData('id'));
            e.target.innerHTML = fromEl.innerHTML;
            e.target.dataset.attachment = fromEl.dataset.attachment;
            fromEl.innerHTML = newContent;
            fromEl.dataset.attachment = newContentId;
            this.sort(e.target, fromEl);
        };
    }

    sort(a, b) {
        axios.post(
            '/attachment/sort',
            {
                entityClassName: this.entityClassName,
                attachmentIds: [
                    a.dataset.attachment,
                    b.dataset.attachment,
                ]
            },
        );
    }

    removeItem(item) {
        axios.post(
            '/attachment/remove',
            {
                entityClassName: this.entityClassName,
                attachmentId: item.dataset.attachment
            },
            {
                validateStatus: (status) => {
                    return true;
                }
            }
        ).then((result) => {
            if (result.status === 200) {
                item.remove();
                this.imageItems.splice(this.imageItems.indexOf(item), 1);

                if (!this.imageItems.length) {
                    this.container.classList.add('_empty');
                }
            } else {
                console.log(result.data.message);
            }
        });
    }
}

document.querySelectorAll('[data-attachments]').forEach((element) => {
    const attachmentManager = new AttachmentManager(element);
});